import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [2,6,4,5];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(auth)/auth": [25,[6]],
		"/(auth)/auth/federated-signout": [~26,[6]],
		"/beta/data-review-demo-eeff": [28,[8]],
		"/beta/data-review-demo-objeto-1": [30,[8]],
		"/beta/data-review-demo-objeto-2": [31,[8]],
		"/beta/data-review-demo-objeto-3": [32,[8]],
		"/beta/data-review-demo-objeto-4": [33,[8]],
		"/beta/data-review-demo-objeto-5": [34,[8]],
		"/beta/data-review-demo-objeto": [29,[8]],
		"/beta/data-review-people": [35,[8]],
		"/beta/pdf-viewer": [36,[8]],
		"/(app)/logger": [11,[2],[3]],
		"/public/verifications/simple/create": [38,[9]],
		"/public/verifications/simple/[id]": [~37,[9]],
		"/(app)/settings": [12,[2,4],[3]],
		"/(app)/settings/api-keys": [~13,[2,4],[3]],
		"/(app)/settings/audit": [~14,[2,4],[3]],
		"/(app)/settings/doc-templates": [~15,[2,4],[3]],
		"/(app)/settings/roles": [16,[2,4],[3]],
		"/(app)/settings/users": [17,[2,4],[3]],
		"/tally": [39],
		"/(edit)/verifications-edit/[id]/[section]": [~27,[7]],
		"/(app)/verifications": [~18,[2],[3]],
		"/(app)/verifications/create": [~20,[2],[3]],
		"/(app)/verifications/create/uploading": [21,[2],[3]],
		"/(app)/verifications/simple/create": [23,[2],[3]],
		"/(app)/verifications/simple/[id]": [~22,[2],[3]],
		"/(app)/verifications/[id]": [~19,[2],[3]],
		"/(app-printable)/verifications/[id]/print": [~24,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';